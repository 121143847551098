.nav-tabs {
  gap: 8px;
  margin-left: 15px;
  margin-right: 15px;
}
.tabData {
  padding: 0px;
  margin: 0px;
}
.nav-tabs .active {
  background-color: white !important;
  color: #495057 !important;
}
#nav-profile-tab {
  border-left: 2px solid #eeeeee !important;
  border-right: 2px solid #eeeeee !important;
  border-top: 2px solid #eeeeee !important;
  border-radius: 5px 5px 0px 0px !important;
}
.tab-content {
  color: black;
  margin-left: 15px;
  margin-right: 15px;
  border: 1px solid #ced4da;
  border-top: none;
  padding: 10px;
  margin-bottom: 20px;
}
#currentAppointment{
  height: 40px;
}
.datefilterRow {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0px !important;
}
.dobrow {
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  margin: 0px !important;
}
#tableData {
  padding-left: 0px !important;
  padding-right: 10px;
}
.datefinInput {
  display: flex;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.dateText {
  color: black;
  align-items: center;
  display: flex;
  white-space: noWrap;
}
.datefilterRow {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
  padding: 0px !important;
}
.visit_History {
  color: #004879;
  text-align: right;
  font-size: larger;
  margin: auto;
  margin-right: 5px;
  font-weight: 900;
  cursor: pointer;
}
.vist_survey_status {
  color: #004879;
  text-align: right;
  font-size: larger;
  margin: auto;
  /* margin-right: 0px; */
  font-weight: 900;
  cursor: pointer;
}
#appDate {
  gap: 8px;
  margin-left: 15px;
  margin-right: 15px;
}
.react-datepicker-wrapper {
  display: flex !important ;
  align-items: center;
}
/* @media (max-width: 1000px) {
  .react-datepicker-wrapper {
    width: 200px;
  }
} */
.react-datepicker-wrapper {
  width: 100%;
}
#surveysListEmail {
  width: 100%;
  max-height: calc(100% - 35px);
  overflow-y: auto;
  background-color: #e9ecef;
  color: black;
  border-radius: 20px;
  padding: 20px;
  top: 50%;
  left: 50%;
  text-align: left;
}
#visitFormdata {
  margin-bottom: 10px;
  padding-right: 0px;
}
#expand {
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  color: #004879;
  padding: 10px;
  display: flex;
  gap: 20px;
  font-weight: bold;
  max-width: 100%;
  cursor: pointer;
  margin: 0 !important;
}
#visitSelectsurveys {
  justify-content: space-between;
}
.surveyandemail {
  background-color: #ced4da;
  padding: 15px;
}
#visitEmail {
  justify-content: space-between;
}
.expandheaderText {
  display: flex;
  gap: 20px;
  padding: 0px !important;
  overflow: overlay;
}
.visitSurveyData {
  display: flex;
  justify-content: left;
  gap: 10px;
  align-items: center;
}
.formHeader {
  color: #004879;
  font-size: large;
  font-weight: bold;
}
@media (min-width: 992px) {
  .custom-lg-2 {
    -ms-flex: 0 0 13.666667% !important;
    flex: 0 0 13.666667% !important;
    max-width: 13.666667% !important;
  }
  .custom-lg-3 {
    -ms-flex: 0 0 22% !important;
    flex: 0 0 22% !important;
    max-width: 22% !important;
  }
  .custom-lg-4 {
    -ms-flex: 0 0 34.666667% !important;
    flex: 0 0 34.666667% !important;
    max-width: 34.666667% !important;
  }
  .custom-lg-1 {
    -ms-flex: 0 0 11.666667% !important;
    flex: 0 0 10.666667% !important;
    max-width: 11.666667% !important;
  }
  .customCheckboxColumn {
    max-width: 5% !important;
  }
  .customPatientColumn {
    flex: 0 0 100% !important;
    max-width: 16.5% !important;
  }
  .customDetailsColumn {
    flex: 0 0 100% !important;
    max-width: 16.5% !important;
  }
}
.v_ellipsis {
  text-overflow: ellipsis;
  overflow: auto;
}
.v_ellipsis:hover {
  white-space: normal !important;
  overflow: visible !important;
  overflow-wrap: break-word !important;
}
.patientData:hover {
  background-color: #fff;
}
.contact {
  background-color: transparent !important;
  border: NONE !important;
  color: #004879 !important;
  text-decoration-line: underline;
  /* width: 100%; */
  text-overflow: ellipsis;
  max-width: 80%;
  cursor: pointer;
  overflow: hidden;
  /* word-wrap: break-word; */
}
.iconParent {
  display: flex;
  align-items: center;
  width: 100%;
}
#contactPopup {
  position: fixed;
  background-color: #fff;
  color: #004879;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  margin: 10px;
  padding: 15px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  /* border:5px solid white; */
  font-weight: bold;
  font-size: 16px;
}
.patientwithforms {
  background-color: #e9ecef;
  border: 2px solid #ced4da;
  padding: 20px;
  margin: 15px !important;
  margin-bottom: 0px !important;
}
#previousscreen {
  color: #004879;
  font-size: large;
  font-weight: bold;
  margin: auto;
  cursor: pointer;
  text-align: end;
  margin-top: 10px;
}
.visitpatientDataTop {
  padding: 0px;
  background-color: #ced4da;
  display: flex;
  align-items: center;
  margin: 15px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  font-family: "Poppins", sans-serif;
  
}
.surveyName:hover {
  white-space: normal !important;
  overflow: visible !important;
  overflow-wrap: break-word !important;
}
.popupWindows {
  position: fixed;
  background-color: #fff;
  color: #004879;
  font-family: "Poppins", sans-serif;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  padding: 15px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  /* border:5px solid white; */
  font-weight: bold;
  font-size: 16px;
}
.appointmentDatePickers{
  border: 1px solid #ced4da; 
  border-radius: 8px;
  overflow: hidden;
  margin-right: 5px; 
  padding: 10px 
}
@media (max-width: 500px) {
  .popupWindows {
    width: 95%;
  }
}
@media (min-width: 500px) and (max-width : 850px) {
  .popupWindows {
    width: 65%;
  }
}
@media (max-width: 1000px) {
  .appointmentDatePickers{
    width: 20%;
  }
  .custom-input{
    margin-right: 0px;
    width:200px
  }
  .datefinInput{
    margin-right: 0px;
    width: 200px;
  }
}
@media (min-width: 1000px) {
  .appointmentDatePickers{
    width: 80%;
  }
  .custom-input{
    margin-right: 10px;
    width:100%;
  }
  .datefinInput{
    margin-right: 10px;
    width: 100%;
  }
}
@media (max-width: 1350px) {
  .dateText{
    white-space: normal;
  }
}
input[type="date"]::-webkit-clear-button {
  display: none;
}
.custom-input{
  background-color: #fff;
  display: flex;
  border : none;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
}
.custom-datetext{
  border:none;
}
