#patient_information {
  background: #ffffff;
  color: #ffffff;
  overflow: hidden;

  width: 100%;

  padding-left: 0%;
}

.piTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 30px;
  color: #004879;
  padding-top: 30px;
  font-size: calc(1.325rem + 0.9vw);
}

.adminPageTitle {
  font-weight: bold;
  font-size: 20px;
  color: #004879;
  padding-top: 30px;
  font-size: calc(1.325rem + 0.9vw);
}

.lastDate{
  font-size: 15px;
}
.finInput {
  display: flex;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.finInput:disabled {
  display: flex;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #e0e0e0;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 0.25rem;
}

.lookupInput:disabled{
  background-color: #e7e7e7;
}
.arrow-input {
  background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iNyIgdmlld0JveD0iMCAwIDEyIDciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEgMWw1IDUgNS01IiBzdHJva2U9IiMwMDAiIHN0cm9rZS13aWR0aD0iMiIvPjwvc3ZnPg==') no-repeat right 0.2rem center;
  background-size: 10px;
  -webkit-appearance: none; 
}
.force-opaque::placeholder {
  opacity: 1;
}

.searchButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #004879;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 170px;
  height: 40px;
  white-space: nowrap;
}

.viewMoreButton {
  float: left;
  padding: 4px 6px;
  border-radius: 100px;
  background: #004879;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 150px;
  height: 40px;
  white-space: nowrap;
}

.searchButton:disabled {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #ced4da;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 170px;
  height: 40px;
  white-space: nowrap;
}

.cancelButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #805791;
  align-items: center;
  color: #E9ECEF;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 170px;
  height: 40px;
  white-space: nowrap;
}

.cancelButton:disabled {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #ced4da;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 170px;
  height: 40px;
  white-space: nowrap;
}

.emailConfirmationScreenButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #004879;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 100px;
  height: 40px;
  white-space: nowrap;
}

.emailConfirmationScreenButton:disabled {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #ced4da;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 100px;
  height: 40px;
  white-space: nowrap;
}

.emailConfirmationCancelButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #805791;
  align-items: center;
  color: #E9ECEF;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 100px;
  height: 40px;
  white-space: nowrap;
}

.registrationFormList{
  justify-content: center;
  text-align: start;
}

.dob {
  color: grey;
  align-items: center;
  text: center;
  align-self: center;
  margin: 5px;
  margin-left: 14px;
}

.dobBody {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 100%;
  display: flex;
}

input:focus {
  outline: none !important;
  /* border:1px solid red; */
  /* box-shadow: 0 0 10px #719ECE; */
}

.custom-datepicker {
  border: none;
  width: 100%;
}

.react-datepicker-wrapper {
  display: flex !important;
  align-items: center;
  width: 100%;
}

.input-with-icon {
  position: relative;
}

.calendar-icon {
  color: grey;
  margin: 10px;
  cursor: pointer;
}

.start_button {
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
}

.submit_button {
  border-radius: 60px;
  /* background-color:#805791 ; */
  color: #e9ecef;
  cursor: pointer;
  width: 60%;
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: center;
  text-align: center;
  min-width: 130px;
  align-items: center;
  gap: 5px;
  /* width:100%; */
  font-weight: bold;
}

.submit_button_active {
  border-radius: 60px;
  /* background-color:#805791 ; */
  color: #e9ecef;
  cursor: pointer;
  width: 60%;
  text-align: center;
  padding: 8px 12px;
  font-size: 14px;
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: center;
  text-align: center;
  min-width: 130px;
  align-items: center;
  gap: 5px;
  /* width:100%; */
  font-weight: bold;
  background-color: rgb(0, 116, 194);
  color: rgb(255, 255, 255);
}

.cancelButtonBanner {
  border-radius: 60px;
  width: 20%;
  color: #e9ecef;
  cursor: pointer;
  text-align: center;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.patientsBanner {
  background: #004879;
  color: white;
  margin-bottom: 10px;
  padding: 20px;
  box-shadow: 0 4px 12px 0 rgba(73, 65, 65, 0.4),
    0 6px 20px 0 rgba(0, 0, 0, 0.4);
}

#startForms {
  background-color: #f8f8f8;
  color: #004879;
}

#emailForms {
  background-color: #f8f8f8;
  color: #004879;
}

.submit_button_cancel {
  /* border-radius: 60px; */
  /* background-color:#805791 ; */
  color: #e9ecef;
  cursor: pointer;
  /* width: 60%; */
  text-align: center;
  margin-top: -15px;
  margin-right: -12px;
  padding-top: 7px;
  padding-bottom: 7px;
  font-size: 14px;
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
  /* width:100%; */
  font-weight: bold;
}

#patientforms {
  margin-top: 10vh;
  color: #004879;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
}

.submit_button_back {
  color: #053964;
  cursor: pointer;
  min-width: 130px;
  text-align: center;
  padding: 8px 12px;
  font-size: 20px;
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: right;
  text-align: center;
  align-items: center;
  gap: 5px;
  font-weight: bold;
}

.tabs {
  margin: 15px;
}

.nav-tabs .nav-link {
  border-radius: 0 !important;
  font-weight: bold;
  font-size: 20px;
  border: none !important;
}

.nav-tabs .active {
  background-color: #004879 !important;
}

.tab-content .active {
  background-color: white !important;
}

.formsHeder {
  margin-bottom: 10px;
  padding: 15px;
  margin-left: 0px;
  margin-right: 0px;
  align-items: center;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
  /* background-color: #004879 !important; */
  border: solid 2px #004879;
  color: #e9ecef;
  /* float: left; */
  width: 100%;
  cursor: pointer;
}

.tabs .nav-tabs {
  border-bottom: 0;
}

.tab {
  border: solid 2px #004879;
}

.chevron {
  height: 25px;
}

.formsBody {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #004879;
  padding: 15px;
}

.formName {
  margin-top: 10px;
  padding: 15px;
  text-align: left;
  width: 100%;
  border-radius: 2px;
  cursor: pointer;
  color: #004879;
  font-size: 20px;
  background-color: #e9ecef;
  border: 0;
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
}

.instruments {
  width: 95%;
  margin: auto;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  text-align: left;
  border-radius: 2px;
  color: #004879;
  font-size: 20px;
  background-color: #fff;
  border: 0;
  padding: 24px;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
    0 1px 5px 0 rgb(0 0 0 / 12%);
}

.custom-checkbox-label {
  font-weight: bold;
  font-size: xx-large;
  display: flex;
  justify-content: end;
  cursor: pointer;
}

.historyText {
  color: #805791 !important;
}

.HistoryTable {
  max-height: 500px;
  overflow: scroll;
  background-color: #E9ECEF;
  font-size: 15px;
  /* position: sticky; */
}

.AdminHistoryTable {
  max-height: 500px;
  overflow: scroll;
  background-color:#e9ecef;
  font-size: 15px;
  padding-bottom: 10px;
  /* position: sticky; */
}

.apEmail:hover {
  white-space: normal !important;
  overflow: visible !important;
  overflow-wrap: break-word !important;
}
.apStatus:hover {
  white-space: normal !important;
  overflow: visible !important;
  overflow-wrap: break-word !important;
}

.apEmail{
  white-space: noWrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}

.apStatus{
  white-space: noWrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

@media (min-width: 992px) {
  .apEmail {
    width: 100%;
  }
}

.loggegInUserEmail:hover {
  white-space: normal !important;
  overflow: visible !important;
  overflow-wrap: break-word !important;
}

#emailMessage {
  position: fixed;
  background: #eeeeee;
  color: #4daa50;
  border-radius: 40px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  margin: 10px;
  padding: 20px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  border: 5px solid white;
  font-weight: bold;
  font-size: 24px;
}

#personlevelEnconter {
  cursor: pointer;
}

#personlevelEnconter:hover {
  font-weight: bold;
  text-decoration: underline;
}

.encounterList {
  position: fixed;
  min-height: 50%;
  max-height: calc(100% - 35px);
  overflow-y: auto;
  background-color: #e9ecef;
  color: black;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  width: 80%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: left;
  border: 1px solid #004879;
}
#lockedPopup {
  position: fixed;
  max-height: calc(100% - 35px);
  overflow-y: auto;
  /* background:#0b5394; */
  background-color: #e9ecef;
  color: #004879;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  /* margin:10px; */
  padding: 20px;
  width: 500px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  border: 1px solid #004879;
}
#encounterError {
  position: fixed;
  max-height: calc(100% - 35px);
  overflow-y: auto;
  /* background:#0b5394; */
  background-color: #e9ecef;
  color: #004879;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  /* margin:10px; */
  padding: 20px;
  width: 35%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  border: 1px solid #004879;
}
@media (max-width: 500px) {
  #encounterError {
    width: 95%;
  }
}
@media (min-width: 500px) and (max-width : 850px) {
  #encounterError {
    width: 80%;
  }
}
.selectEncounterButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #fff;
  align-items: center;
  color: #004879;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 160px;
  text-align: center;
  height: 40px;
  font-weight: bold;
  margin-top: 10px;
}

.encounter-input{
  display: flex;
  width: 100%;
  padding: 0.375rem 3.5rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.encounter-selected-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  background-color:#E9ECEF;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: scroll;
}

.encounter-selected-option {
  display: flex;
  align-items: center;
  padding: 5px 5px;
  font-weight: 100;
  font-size: 12px;
  background-color:#ccc; 
  border-radius: 5px;
  color: #000; 
}

.encounter-remove-button {
  background: none;
  border: none;
  color: #000; 
  font-weight: bold;
  margin-left: 5px;
  cursor: pointer;
}

.addNewEmailText{
  cursor: pointer;
  color: #004879;
  text-decoration: underline;
}


