@font-face {
  font-family: "Poppins";
  src: local('Poppins'), url(../../Font/Poppins/Poppins-Regular.ttf) format('truetype');      
}

/* Now you can use the "Poppins" font in your CSS */
* {
  font-family: "Poppins", sans-serif !important;
  
}
#nav {
  position: sticky;
  background: #004879;
  color: white;
  box-shadow: 0 4px 12px 0 rgba(73, 65, 65, 0.4),
    0 6px 20px 0 rgba(0, 0, 0, 0.4);
}
#navEnviroment {
  position: sticky;
  background: red;
  color: white;
}
.navTab {
  float: left;
  text-align: center;
  display: inline-block;
  padding: 10px;
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
}
.navbar-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}
.navbar-toggler{
  border: 1px solid #fff !important;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0  0.25rem #fff !important;
}
.active {
  background: #0d9ddb !important;
  border-radius: 5px;
  
}
.logo {
  height: 45px;
  width: 200px;
}
.nav-link{
  color: #EEE !important;
  font-weight: 600 !important;
  cursor: pointer;
}

.envDrop {
  display: flex;
  width: 10rem;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.envDrop option:hover {
  background-color: lightblue;
}
.confirmButton {
  padding: 8px 10px;
  border-radius: 100px;
  background: #fff;
  align-items: center;
  color: #004879;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 150px;
  height: 40px;
  font-weight: bold;
}
.domainText{
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-weight: bold;
}
#envConfirmation {
  position: fixed;
  max-height: calc(100% - 20px);
  overflow-y: auto;
  /* background:#0b5394; */
  background-color: #e9ecef;
  color: #004879;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  /* margin:10px; */
  padding: 20px;
  width: 30%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  border: 1px solid #004879;
  overflow-x: hidden;
}
@media (max-width: 500px) {
  #envConfirmation {
    width: 95%;
  }
}
@media (min-width: 500px) and (max-width : 850px) {
  #envConfirmation {
    width: 62%;
  }
}