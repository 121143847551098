body {
    margin: 0;
}

#patient_information_email {

    background: #ffffff;
    color: black;
    height: 100%;
    font-family: "Poppins", sans-serif;

    width: 100%;

    padding-left: 0%;

    z-index: 100;
}

.surveyHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.surveyImage {
    display: flex;
    justify-content: start;
}

.surveyLangauge {
    display: flex;
    height: 40px;
    justify-content: end;

    /* margin: auto; */
}

.language {

    display: inline-block;

    padding: 10px;
    background: #0b5394;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    color: white;
    border-radius: 20px;
    text-align: center;
    width: 100px;
    cursor: pointer;
}

.finInput {

    display: flex;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

#confirm {
    /* width: 10%; */
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 60px;
    background-color: #004879;
    color: #eeeeee;
    padding: 8px 12px;
    font-size: 14px;
    border: none;
    font-weight: bold;
    /* margin-left: 44%;
      margin-top: 20px;
      margin-bottom: 20px ; */
    cursor: pointer;
}

/* MonthPicker.css */
.month-picker,
.date-picker,.year-picker {
    position: relative;
    width: 100%;
}

.month-picker .finInput {
    display: flex;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.month-dropdown {
    margin-top: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    width: 186.33px;
    padding: 8px 0px;
    border-radius: 2%;
    z-index: 1000;
}

.month-dropdown-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
}

.month-item {

    border-radius: 5px;
    display: block;
    text-align: center;
    font-weight: 100;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
}

.date-item {
    border-radius: 5px;
    display: block;
    text-align: center;
    font-weight: 100;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
}

.year-item {
    border-radius: 5px;
    display: block;
    text-align: center;
    font-weight: 100;
    height: 54px;
    line-height: 54px;
    float: left;
    margin: 1%;
    cursor: pointer;
}

.dropdown-days {
    font-weight: 600;
}

.month-item:hover,
.date-item:hover ,
.year-item:hover{
    background-color: #55a2eb;
}

.month-item.highlight,
.date-item.highlight,
.year-item.highlight {
    background-color: #55a2eb;
    font-weight: bold;
}

.date-dropdown {
    margin-top: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    width: 200px;
    padding: 8px 0px;
    border-radius: 2%;
    z-index: 1000;
}

.date-dropdown-container {
    display: grid;

    grid-template-columns: repeat(8, 1fr);
}

.year-dropdown {
    margin-top: 1px;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    width: 200px;
    padding: 8px 0px;
    border-radius: 2%;
    z-index: 1000;
}
.year-dropdown-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
}
.yearToggleButton{
    border: none;
    background: none;
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
}
@media (max-width: 550px) {

    #logo {
        height: 41px;
        width: 200px;
        left: 3px;
    }

}

@media (max-width: 450px) {
    .surveyLangauge {
        margin-bottom: 10px;
        /* justify-content: center; */
    }

    .surveyImage {
        display: flex;
        justify-content: center;
    }
}