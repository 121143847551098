#emailBox {
    position: fixed;
    max-height: calc(100% - 35px);
    overflow-y: auto;
    background-color: #e9ecef;
    color: black;
    border-radius: 20px;
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
    padding: 20px;
    width: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3001;
    text-align: left;
    border: 1px solid #004879;
  }

#emailBox {
    /* height: 100%; */
    width: 30rem;
  }
  #sendEmail:hover {
    background-color: #0074c2;
  }
  
  #sendEmail {
    background-color: #004879;
  }

  #submitCancel {
    background-color: #805791 !important;
  }

  .react-tagsinput {
    background-color: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding-left: 5px;
    padding-top: 5px;
    width: 100%;
  }
  
  .react-tagsinput--focused {
    border-color: #A6A6A6;
  }

  .react-tagsinput-tag:hover {
    white-space: normal !important;
    overflow: hidden !important;
    overflow-wrap: break-word !important;
  }
  
  .react-tagsinput-tag{
    white-space: noWrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  
  /* @media (min-width: 1000px) {
    .apEmail {
      width: 100%;
    }
  } */
  
  .react-tagsinput-tag {
    background-color: #A6A6A6;
    border-radius: 2px;
    border: 1px solid #A6A6A6;
    color: #ffff;
    display: inline-block;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-right: 5px;
    padding: 5px;
    overflow: hidden;
  }
  
  .react-tagsinput-remove {
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
  }
  
  .react-tagsinput-tag a::before {
    content:"  X";
    /* padding-top: 1000px; */
    color: #EEEEEE;
  }
  .tagInputBox{
    display: block;
  }
  .react-tagsinput-input {
    background: transparent;
    border: 0;
    color: #212529;
    font-family: sans-serif;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 6px;
    margin-top: 1px;
    outline: none;
    padding: 5px;
    width: 100%;
  }
  