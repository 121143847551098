.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.search-name{
  min-width: 35%;
  max-width: 300px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner {
  margin: 100px auto 0;
  width: 70px;
  text-align: center;
}

.spinner > div {
  width: 18px;
  height: 18px;
  background-color: #ffffff;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}
#loading {
  position: absolute;
  top: 35%;
  transform: translateY(-35%);
  left: 48%;
  transform: translateX(-48%);
}
#loaderText {
  position: absolute;
  top: 55%;
  transform: translateY(-35%);
  left: 48%;
  transform: translateX(-48%);
}
#overlay {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  padding: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3000;
}
#overlay2 {
  position: fixed;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
  padding: 0px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 3000;
}

.IntEmailRequiredOptions {
  margin: 10px;
}

.IntEmailRequiredOptions input[type="radio"] {
  opacity: 0;
  position: fixed;
  width: 0;
}

.IntEmailRequiredOptions label {
    color: #004879;
    display: inline-block;
    background-color: #ffffff;
    padding: 5px 15px;
    font-family: sans-serif, Arial;
    font-size: 16px;
    border-radius: 4px;
}

.IntEmailRequiredOptions input[type="radio"]:checked + label {
  background-color: #004879;
  border-color: #61dafb;
  color: #ffffff;
}

@media (max-width: 500px) {
  #pin {
    width: 95%;
  }
}
@media (min-width: 500px) and (max-width : 850px) {
  #pin {
    width: 80%;
  }
}
@media(min-width: 850px){
  #pin{
    width: 600px;
  }
}
#pin {
  position: fixed;
  max-height: calc(100% - 35px);
  overflow-y: auto;
  /* background:#0b5394; */
  background-color: #e9ecef;
  color: black;
  border-radius: 20px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  /* margin:10px; */
  padding: 20px;
  /* width: 600px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
  border: 1px solid #004879;
}

@media (max-width: 500px) {
  #beSendMail {
    width: 95%;
  }
}
@media (min-width: 500px) and (max-width : 850px) {
  #beSendMail {
    width: 80%;
  }
}
@media(min-width: 850px){
  #beSendMail{
    width: 600px;
  }
}
#beSendMail {
  position: fixed;
  max-height: calc(100% - 20px);
  overflow-y: auto;
  /* background:#0b5394; */
  background-color: #ffffff;
  color: black;
  border-radius: 10px;
  box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4);
  /* margin:10px; */
  padding: 20px;
  /* width: 600px; */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3001;
  text-align: center;
}

.text-field {
  position: relative;
  /* margin: 10px 2.5px 20px 2.5px; */
}
.lockScreenDetails{
  font-weight: bolder;
  color: #004879;
}
.lockScreenSubText{
  font-weight: light;
  color: black;
}
.finInput {
  /* font-size:16px;
	font-weight:bold;
	border-radius:5px;
	height:25px;
	padding: 10px;
	border:none */
  display: flex;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.submit_button-pin {
  border-radius: 20px;
  background-color: #805791;
  padding: 8px 20px;
  color: #e9ecef;
  cursor: pointer;
  /* width: 60%; */
  text-align: center;
  font-size: 14px;
  margin-bottom: 15px;
  border: none;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  gap: 5px;
  /* width:100%; */
  font-weight: bold;
}
.navTabLogout{
  float: left;
  text-align: center;
  display: inline-block;
  /* padding-top: 100px; */
  /* margin:10px;
	padding:10px; */
  font-weight: bold;
  border-radius: 5px;
  cursor: pointer;
  color: #004879;
}
.surveyHeader {
  display: flex;
  justify-content: space-between;
}
