.user-nav {
  background-color: #e72222;
  box-shadow: 0 4px 4px 4px rgba(26, 25, 25, 0.2);
}

@media (max-width: 500px) {
  .popup-form {
    width: 95%;
  }
}

@media (min-width: 500px) and (max-width : 850px) {
  .popup-form {
    width: 80%;
  }
}

@media(min-width: 850px) {
  .popup-form {
    width: 600px;
  }
}

.popup-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.63);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1050;
}

.userInfo {
  padding: 0px;
  background-color: #e9ecef;
  align-items: center;
  font-family: "Poppins";
}

.resetButton {
  color: #805791;
  cursor: pointer;
}

.popup-form {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  position: relative;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  min-height: 250px;
}

.HistoryData {
  background-color: #e9ecef;
  border-top: 1px solid black;
}

.adminSurveyTable {
  width: 90%;
  margin-top: 10px;
}

.surveysTable {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #e9ecef;
}

.surveysErrorBanner {
  padding: 0px;
  background-color: #e9ecef;
  width: 100%;
  margin: auto !important;
  margin-top: 10px !important;
  align-items: center;
  font-family: "Poppins";
}

.resubmitButton {
  padding: 10px 12px;
  border-radius: 100px;
  background: #004879;
  color: #fff;
  border: none;
  cursor: pointer;
  height: 40px;
  font-weight: bold;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resubmitButton:disabled {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #ced4da;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 120px;
  height: 40px;
  white-space: nowrap;
}

.form-select:focus,
.form-control:focus {
  box-shadow: none;
  border: 1px solid rgb(180, 180, 180);
}

.form-lable {
  box-sizing: border-box;
  color: rgb(0, 72, 121);
  cursor: default;
  display: inline-block;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 24px;
  line-height: 24px;
  margin-top: 8px;
  text-align: left;
  text-size-adjust: 100%;
  width: 470px;
}


.popup-form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-form-title {
  margin: 0;
  font-weight: bold;
}

.desktop-sidebar {
  background-color: #edf2f4;
}

.nav-link {
  color: #333;
  display: flex;
  align-items: center;
}

.tableBgStyle {
  background-color: "#CED4DA";
  color: "#004879";
}

.additionalStyle {
  margin-inline: 1.1rem;
  color: #333 !important;
}

.nav-link:hover {
  background-color: rgba(97, 97, 97, 0.1);
}

.nav-link i {
  margin-right: 10px;
}

.nav-link:focus,
.nav-link:active {
  background-color: rgba(97, 97, 97, 0.1);
}

.btn-close {
  align-self: flex-end;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
}

.btn-primary {
  font-size: 1.1rem;
}


.dropdown-list {
  right: 0px;
  left: auto;
}


ul.hover-suggestion li:hover:hover {
  color: #fff;
  background-color: #c7c7c7;

}

.search-icon {
  padding-right: 30px;
  background: transparent url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' class='bi bi-search' viewBox='0 0 16 16'%3E%3Cpath d='M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z'%3E%3C/path%3E%3C/svg%3E") no-repeat;
  background-size: 16px;
  background-position: 98% center;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
}

.condition {
  width: 100%;
  background-color: #f8f8f8;
  padding: 10px 20px;
  font-size: 15px;
}

.searchButton-SurveyManager {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #004879;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  /* max-width: 170px; */
  min-width: 120px;
  height: 40px;
  white-space: nowrap;
}

@media (max-width: 939px) {
  .searchButton-SurveyManager {
    min-width: 170px;
  }
}

.addButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #fff;
  align-items: center;
  color: #004879;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 140px;
  height: 40px;
  white-space: nowrap;
}

.addButton:disabled {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #ced4da;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  min-width: 170px;
  height: 40px;
  white-space: nowrap;
}

.desktop-sidebar {
  position: fixed;
  top: 1;
  height: 100%;
  z-index: 1000;
  background-color: #edf2f4;
}

.main-content {
  margin: auto;
  margin-left: 350px;
  padding: 20px;
}

@media (max-width: 992px) {
  .desktop-sidebar {
    position: relative;
    width: 100%;
    height: auto;
  }

  .main-content {
    margin-left: 0;
  }
}

.searchsurveycancelButton {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #805791;
  align-items: center;
  color: #E9ECEF;
  border: none;
  border-left: none;
  cursor: pointer;
  height: 40px;
  white-space: nowrap;
}

.searchsurveycancelButton:disabled {
  float: left;
  padding: 8px 10px;
  border-radius: 100px;
  background: #ced4da;
  align-items: center;
  color: #fff;
  border: none;
  border-left: none;
  cursor: pointer;
  height: 40px;
  white-space: nowrap;
}

.encounter-selected-options-container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  background-color: #E9ECEF;
  border-radius: 5px;
  max-height: 150px;
  overflow-y: auto
}

.encounter-selected-options-container::-webkit-scrollbar {
  display: none;
}

.dropdown-list::-webkit-scrollbar {
  display: none;
}

.inputWrapper {
  position: absolute;

}

.toggleInput {
  position: absolute;
  left: -9999px;
  top: -9999px;
}

.toggleInput:checked+.slider {
  background-color: #004879;
}

.toggleInput:checked+.slider::before {
  left: 27px;
}

.toggleInput:checked:focus+.slider::before {
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
}

.slider {
  display: flex;
  cursor: pointer;
  width: 50px;
  height: 25px;
  border-radius: 100px;
  background-color: #bfbfbf;
  position: relative;
  transition: background-color 0.2s;
  pointer-events: none;
}

.slider::before {
  content: "";
  position: absolute;
  top: 2.2px;
  left: 2px;
  width: 21px;
  height: 21px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 2px 4px #bfbfbf;
}

@media (min-width: 992px) {
  .toggle-lg-4 {
    -ms-flex: 0 0 30% !important;
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}
@media (min-width: 500px) {
  .customToggle-lg-4 {
    -ms-flex: 0 0 30% !important;
    flex: 0 0 30% !important;
    max-width: 30% !important;
  }
}

.dropdown-toggle {
  display: flex !important;
  text-align: left !important;
  width: 100% !important;
  justify-content: space-between !important;
  align-items: center !important;
  background-color: white !important;
  border: 0px !important;
}

.dropdown-menu {
  max-height: 50vh !important;
  overflow-y: scroll !important;
  left: -13px !important;
  overflow-wrap: break-word;
}

.dropdowntoggle {
  overflow: hidden;
  text-overflow: ellipsis;
}

.elementDate {
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  overflow: hidden;
  padding: 0.375rem 0.50rem
}
.elementDate::placeholder{
  font-size: 13px;
  font-weight: bold;
}
.elementFilter {
  width: 100%;
}


@media (min-width: 768px) and (max-width: 1200px),
(min-width: 1200px) {
  .elementDate {
    width: 90%;
  }
}
@media (min-width: 576px) and (max-width: 767px)
 {
  .elementDate {
    width: 150%;
  }
}


@media (min-width: 992px) and (max-width: 1200px) {
  .elementDate {
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .elementFilter {
    width: 90%;
  }
}
.priority{

  display: flex;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}