#pagecontainer {
    margin: 0 auto;
    max-height: 100%;
    padding: 5px 0 10px;
    text-align: left;
    max-width: 798px;
	background: #004877;
	border: 0;
}
#container{
	margin: 0 0 0 8px;
    padding: 5px 15px 0px 15px;
    text-align: left;
}
#pagecontent {
    margin: 0px;
	margin-top: 70px;
}
.progress-bar {
    animation: progressAnimation 1s ease forwards; 
  }
@keyframes progressAnimation {
    from {
      width: 0; 
    }
    to {
      width: 100%; 
    }
}