.finlist {
  overflow-y: auto;
  overflow-x: hidden;
  /* height: 100%; */
}
.reset {
  color: #004879;
  display: flex;
  justify-content: end;
}
.patientDataTop {
  padding: 0px;
  background-color: #ced4da;
  display: flex;
  align-items: center;
  font-family: "Poppins";
}
.patientsinfoTop {
  color: #004879;
  font-family: "Poppins";
  font-weight: bolder;
  display: flex;
  align-items: center;
}

.projectSearchInfoTop{
  color: #004879;
  font-family: "Poppins";
  font-weight: bolder;
}

@media (min-width: 990px) and (max-width : 1400px) {
  .apptDisplayScreen{
    display: none;
  }
  .apptDisplayMidScreen{
    display: block;
  }
}
@media (max-width: 989px) {
  .apptDisplayMidScreen {
    display: none;
  }
}
@media (min-width: 1401px) {
  .apptDisplayMidScreen {
    display: none;
  }
}
.patientData {
  padding: 0px;
  background-color: #e9ecef;
  align-items: center;
  font-family: "Poppins";
  border-top: 1px solid black;
}
.patientsinfo {
  color: #004879;
  font-family: "Poppins";
  font-weight: lighter;
}

.completedHistoryTop{
  word-break: break-all;
}

@media (max-width: 1000px) {
  .selectFin{
    margin-bottom: 5px;
  }
  
}
@media (min-width: 1000px) {
  .selectFin{
    margin-bottom: 0px;
  }
  
}
.selectFin {
  border-radius: 60px;
  background-color: #004879;
  color: #eeeeee;
  padding: 8px 10px;
  font-size: 13px;
  border: none;
  min-width: 130px;
  font-weight: bold;
  cursor: pointer;
  text-decoration: none;
}
.selectvisits {
  border-radius: 60px;
  background: #805791;
  color: #fff;
  min-width: 130px;
  padding: 8px 10px;
  font-size: 13px;
  border: none;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;

  /* width: 100%; */
  /* font-weight: bold; */
}
.selectPatient {
  /* float: right; */
  border-radius: 60px;
  background-color: #fff;
  border-color: #004879;
  color: #004879;
  padding: 8px 12px;
  font-size: 12px;
  border: solid;
  text-align: left;
  /* min-width: 120px; */
  /* width: 100%; */
  font-weight: bold;
}

.selectPatient:hover {
  background-color: #0074c2;
  color: #fff;
}
.Accordians {
  background-color: #e9ecef;
  gap: 10px;
  margin-bottom: 10px;
  padding-bottom: 20px;
}
.accordionButton {
  background-color: #015a96;
  color: #eeeeee;
  width: 90%;
  border: none;
  padding: 8px 20px;
  border-radius: 5px;
  text-align: left;
  display: flex;
  justify-content: space-between;
}
.accordionBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.finlistbody {
  background-color: #e9ecef;
}
.FinDataTop {
  padding: 0px;
  background-color: #ced4da;
  width: 100%;
  margin: auto !important;
  margin-top: 10px !important;
  align-items: center;
  font-family: "Poppins";
}
.encounterTable {
  width: 80%;
  margin-top: 10px;
}
.patientFIN {
  padding: 0px;
  margin: auto;
  width: 100%;
  background-color: #fff;

  align-items: center;
  font-family: "Poppins";

  border-top: 1px solid black;
}
.popup-select {
  position: absolute;
  z-index: 1000; /* Adjust the z-index as needed */
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  max-height: 150px; /* Adjust the maximum height as needed */
  margin-top: 4%;
  margin-left: 3%; /* Adjust the top margin as needed */
}
.css-b62m3t-container {
  width: 150px;
}
.filter-icon {
  cursor: pointer;
  color: #004879;
  margin-left: 10px;
}
